// discountReducers.js
import types from './discountConstants';

const INIT_STATE = {
    discounts: [],
    discount: null,
    discountTypes: [],
    discountScopeTypes: [],
    loading: false,
    success: null,
    error: null
};

const Discount = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            };

        case types.GET_DISCOUNTS:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.GET_DISCOUNTS_SUCCESS:
            return {
                ...state,
                discounts: action.payload.data.items,
                loading: false
            };
        case types.GET_DISCOUNTS_FAILED:
        case types.GET_DISCOUNT_FAILED:
        case types.GET_DISCOUNT_TYPES_FAILED:
        case types.GET_DISCOUNT_SCOPE_TYPES_FAILED:
        case types.GET_DISCOUNT_SCOPE_VALUES_FAILED:
        case types.PATCH_DISCOUNT_FAILED:
        case types.PATCH_DISCOUNT_ACTIVATION_STATE_FAILED:
        case types.POST_DISCOUNT_FAILED:
        case types.DELETE_DISCOUNT_FAILED:
            return {
                ...state,
                success: null,
                error: action.payload,
                loading: false
            };

        case types.GET_DISCOUNT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.GET_DISCOUNT_SUCCESS:
            return {
                ...state,
                discount: action.payload.data,
                loading: false
            };

        case types.PATCH_DISCOUNT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_DISCOUNT_SUCCESS:
            return {
                ...state,
                success: 'DISCOUNT.PATCH_DISCOUNT_SUCCESS',
                loading: false
            };

        case types.PATCH_DISCOUNT_ACTIVATION_STATE:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_DISCOUNT_ACTIVATION_STATE_SUCCESS:
            return {
                ...state,
                success: 'DISCOUNT.PATCH_DISCOUNT_SUCCESS',
                loading: false
            };

        case types.POST_DISCOUNT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_DISCOUNT_SUCCESS:
            return {
                ...state,
                success: 'DISCOUNT.POST_DISCOUNT_SUCCESS',
                loading: false
            };

        case types.DELETE_DISCOUNT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.DELETE_DISCOUNT_SUCCESS:
            return {
                ...state,
                success: 'DISCOUNT.DELETE_DISCOUNT_SUCCESS',
                loading: false
            };

        case types.GET_DISCOUNT_TYPES:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.GET_DISCOUNT_TYPES_SUCCESS:
            return {
                ...state,
                discountTypes: action.payload.data,
                success: null,
                loading: false
            };

        case types.GET_DISCOUNT_SCOPE_TYPES:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.GET_DISCOUNT_SCOPE_TYPES_SUCCESS:
            return {
                ...state,
                success: null,
                discountScopeTypes: action.payload.data,
                loading: false
            };

        case types.GET_DISCOUNT_SCOPE_VALUES:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.GET_DISCOUNT_SCOPE_VALUES_SUCCESS:
            return {
                ...state,
                success: null,
                discountScopeValues: action.payload.data,
                loading: false
            };

        default:
            return state;
    }
};

export default Discount;